<script>
import PodcastDetails from "./_Details.vue";
import PodcastImages from "./_Images.vue";
import PodcastLinks from "./_Links.vue";

export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
    PodcastDetails,
    PodcastImages,
    PodcastLinks,
  },
  methods: {
  }
};
</script>

<template>
  <div class="card mt-xxl">
    <div class="card-header">
      <ul class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
        <li class="nav-item">
          <a class="nav-link text-body active" data-bs-toggle="tab" href="#podcastDetails" role="tab" aria-selected="true">
            Podcast Details
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-body" data-bs-toggle="tab" href="#images" role="tab" aria-selected="false">
            Avatar &amp; Cover
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-body" data-bs-toggle="tab" href="#podcastLinks" role="tab" aria-selected="false">
            Links 
          </a>
        </li>
      </ul>
    </div>
    <div class="card-body p-4">
      <div class="tab-content">
        <div class="tab-pane active" id="podcastDetails" role="tabpanel">
          <PodcastDetails v-bind:data="data"></PodcastDetails>
        </div>
        <div class="tab-pane" id="images" role="tabpanel">
          <PodcastImages v-bind:data="data"></PodcastImages>
        </div>
        <div class="tab-pane" id="podcastLinks" role="tabpanel">
          <PodcastLinks v-bind:data="data"></PodcastLinks>
        </div>
      </div>
    </div>
  </div>
</template>